html,
body,
#root {
  min-height: 100vh;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'), url(./Fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: local('Raleway-SemiBold'), url(./Fonts/Raleway-SemiBold.ttf);
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway-Bold'), url(./Fonts/Raleway-Bold.ttf);
}

@font-face {
  font-family: 'Raleway-Italic';
  src: local('Raleway-Italic'), url(./Fonts/Raleway-Italic.ttf);
}

p,
input,
textarea,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label,
button,
div {
  font-feature-settings: 'lnum' 1;
}

input  {
  font-feature-settings: 'lnum' 1!important;
}

.bracket {
  margin-top: 32px;
  margin-left: -4px; /* Because of game number margin left */
}

.bracket-round {
  position: relative;
}